import {
  CreateButton,
  CRUDLayout,
  Pagination,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "components"
import {
  ButtonCancel,
  ButtonCreate,
  ButtonFilter,
  ButtonUpdate,
  DataStatus,
  InputSearch
} from "components2"
import { Formik } from "formik"
import { useModalConfirm } from "hooks2"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { ButtonGroup, Modal } from "react-bootstrap"
import { useMutation, useQuery } from "react-query"
import { tableNumber } from "utilities2"
import ItemCheclistApi from "./__ItemChecklistApi__"
import {
  FormItemChecklistContent,
  ModalFilter,
} from "./__ItemChecklistComps__"
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  MODAL_TITLE,
} from "./__ItemChecklistUtils__"

export const ItemChecklistList = ({ setNavbarTitle }) => {
  const modalConfirm = useModalConfirm();

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    jenis_checklist: undefined,
    active: false,
  });
  const [modal, setModal] = useState({
    type: "CREATE", // CREATE | UPDATE | DETAIL
    show: false,
    data: {},
  });
  const [modalFilter, setModalFilter] = useState(false);

  const getPengajuanPettyCash = useQuery(
    ["pengajuan_petty_cash", "list", filter],
    () => ItemCheclistApi.getList(filter)
  );

  const createPengajuanPettyCash = useMutation((data) =>
    ItemCheclistApi.create(data)
  );

  const updatePengajuanPettyCash = useMutation((data) =>
    ItemCheclistApi.update(data)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = () =>
    setModal({ show: true, type: "CREATE" });

  const onUpdateButtonClickHandler = (data) =>
    setModal({ show: true, type: "UPDATE", data });

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: "md",
      type: modal.type === "CREATE" ? "create" : "update",

      component: (
        <Formik initialValues={values}>
          <FormItemChecklistContent action="DETAIL" />
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        if (modal.type === "CREATE") {
          return await createPengajuanPettyCash
            .mutateAsync(formSubmitValueMapper(values), resetForm)
            .then(() => {
              modalConfirm.infoSuccess({
                title: "Data Item Checklist Berhasil Ditambah",
                customTextInfoDetail: "Data telah tersimpan ke database",
                size: "md",
                onHide: () => {
                  modalConfirm.close();
                  dismissModal(resetForm);
                },
              });
              getPengajuanPettyCash.refetch();
            })
            .catch((err) => {
              modalConfirm.infoError({
                title: "Data Item Checklist Gagal Ditambah",
                customTextInfoTitle: "Penyebab Kegagalan:",
                customTextInfoDetail: err?.response?.data?.message,
              });
            });
        }

        await updatePengajuanPettyCash
          .mutateAsync(formSubmitValueMapper(values))
          .then(() => {
            modalConfirm.infoSuccess({
              title: "Data Item Checklist Berhasil Diubah",
              customTextInfoDetail: "Data telah tersimpan ke database",
              size: "md",
              onHide: () => {
                modalConfirm.close();
                dismissModal(resetForm);
              },
            });
            getPengajuanPettyCash.refetch();
          })
          .catch((err) =>
            modalConfirm.infoError({
              title: "Data Item Checklist Gagal Diubah",
              customTextInfoTitle: "Penyebab Kegagalan:",
              customTextInfoDetail: err?.response?.data?.message,
            })
          );
      },
      onHide: () => modalConfirm.close(),
    });
  };

  const dismissModal = (resetForm) => {
    resetForm();
    setModal({ data: null, show: false, type: "" });
  };

  useEffect(() => setNavbarTitle("Item Checklist"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPengajuanPettyCash.isLoading || getPengajuanPettyCash.isError ? (
        <DataStatus
          loading={getPengajuanPettyCash.isLoading}
          text={
            getPengajuanPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">List Data Item Checklist</small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th className="p-1">Kode Item Checklist</Th>
                <Th className="p-1">Nama Item Checklist</Th>
                <Th className="p-1">Jenis Item Checklist</Th>
                <Th className="p-1">Keterangan</Th>
              </Tr>
            </THead>
            <TBody>
              {getPengajuanPettyCash?.data?.data?.length > 0 ? (
                getPengajuanPettyCash?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup size="sm">
                        <UpdateButton
                          icon
                          noText
                          variant="success"
                          onClick={() => onUpdateButtonClickHandler(e)}
                        />
                      </ButtonGroup>
                    </TdFixed>
                    <Td>{e.kode_item_checklist ?? "-"}</Td>
                    <Td>{e.nama_item_checklist ?? "-"}</Td>
                    <Td>{e.nama_jenis_checklist ?? "-"}</Td>
                    <Td>{e.keterangan ?? "-"}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getPengajuanPettyCash?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getPengajuanPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {/* Modal Create | Update | Detail */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal?.data)}
        validationSchema={formValidationSchema}
        onSubmit={onSubmitForm}
      >
        {(formik) => (
          <Modal
            show={modal.show}
            onHide={() => dismissModal(formik.resetForm)}
          >
            <Modal.Header closeButton>
              <span>{MODAL_TITLE[modal.type]} Item Checklist </span>
            </Modal.Header>
            <Modal.Body>
              <FormItemChecklistContent action={modal.type} />
            </Modal.Body>

            <Modal.Footer>
              <div className="d-flex justify-content-center">
                <ButtonCancel
                  className="mr-2"
                  disabled={formik.isSubmitting}
                  onClick={() => dismissModal(formik.resetForm)}
                />

                {modal.type === "CREATE" && (
                  <ButtonCreate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}

                {modal.type === "UPDATE" && (
                  <ButtonUpdate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
      {modalFilter && (
        <ModalFilter
          data={filter}
          setData={setFilter}
          toggle={onFilterButtonClickkHandler}
        />
      )}
    </CRUDLayout>
  );
};
