import React from "react"
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import {
  IoPencilOutline
} from 'react-icons/io5'
import { GuestMiddleware } from "utilities/GuestMiddleware"

const UpdateButton = ({
  text,
  type,
  size,
  variant,
  disable,
  className,
  style,
  children,
  onClick,
  tooltip = true,
  tooltipText,
  tooltipPlacement,
  ...rest
}) => {
  return (
    <GuestMiddleware>
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={tooltip ? <Tooltip>{tooltipText ?? 'Ubah data'}</Tooltip> : <div></div>}
      >
        <Button
          {...rest}
          type={type}
          variant="success"
          onClick={onClick}
          disabled={disable}
          className={className}
          size={size}
          style={style}
        >
          <IoPencilOutline />
          {text && (
            <span className="ml-2 text-uppercase">
              {text}
            </span>
          )}
        </Button>
      </OverlayTrigger>
    </GuestMiddleware>
  )
}

export default UpdateButton