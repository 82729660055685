import {
  Alert,
  CreateButton,
  CRUDLayout,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components'
import {
  Button,
  ButtonFilter,
  DataStatus,
  InputSearch
} from 'components2'
import { Formik } from 'formik'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { useQuery } from 'react-query'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import { DateConvert } from 'utilities'
import { tableNumber } from 'utilities2'
import FormChecklistApi from './__FormChecklistApi__'
import { ModalFilter } from './__FormCheclistComps__'

export const FormChecklistList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    per_page: 10,
    tgl_maintenance_request_start: undefined,
    tgl_maintenance_request_end: undefined,
    tgl_form_checklist_start: undefined,
    tgl_form_checklist_end: undefined,
    id_item_aset: undefined,
    status_checklist: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const [collapsedIndex, setCollapsedIndex] = useState(-1);

  const getAnalisaChecklist = useQuery(
    ['analisa_checklist', 'list', filter],
    () => FormChecklistApi.getList(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_maintenance_request_start: filter?.tgl_maintenance_request_start,
    tgl_maintenance_request_end: filter?.tgl_maintenance_request_end,
    tgl_form_checklist_start: filter?.tgl_form_checklist_start,
    tgl_form_checklist_end: filter?.tgl_form_checklist_end,
    id_item_aset: filter?.id_item_aset,
    status_checklist: filter?.status_checklist,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => {
    setNavbarTitle('Form Checklist');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="md"
            icon
            onClick={() => history.push('form-checklist/maintenance-request')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getAnalisaChecklist.isLoading || getAnalisaChecklist.isError ? (
        <DataStatus
          loading={getAnalisaChecklist.isLoading}
          text={
            getAnalisaChecklist.isLoading ? 'Memuat . . .' : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">List Data Form Checklist</small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Form Checklist</ThFixed>
                <ThFixed>Informasi Maintenance Request</ThFixed>
                <Th className="p-1">Item Aset</Th>
                <Th className="p-1">Catatan</Th>
                <ThFixed>Status Checklist</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getAnalisaChecklist?.data?.data?.length > 0 ? (
                getAnalisaChecklist?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup size='sm'>
                        <ReadButton
                          icon
                          noText
                          onClick={() =>
                            history.push(
                              '/transaksi/form-checklist/detail/' +
                                e?.id_form_checklist
                            )
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                    <TdFixed>
                      <div>
                        {e?.tgl_form_checklist
                          ? DateConvert(new Date(e?.tgl_form_checklist))
                              .defaultDMY
                          : '-'}
                      </div>
                      <div>{e?.no_form_checklist ?? '-'}</div>
                    </TdFixed>
                    <TdFixed>
                      <div>
                        {e?.tgl_maintenance_request_master
                          ? DateConvert(
                              new Date(e?.tgl_maintenance_request_master)
                            ).defaultDMY
                          : '-'}
                      </div>
                      <div>{e?.no_maintenance_request_master ?? '-'}</div>
                    </TdFixed>
                    <Td>
                      {e.list_item_aset?.length === 0 ? (
                        '-'
                      ) : (
                        <>
                          <ul className="pl-3 m-0">
                            {e?.list_item_aset
                              ?.filter((_val, indexPermintaan) => {
                                if (index === collapsedIndex) {
                                  return (
                                    indexPermintaan <= e.list_item_aset.length
                                  );
                                } else {
                                  return indexPermintaan <= 2;
                                }
                              })
                              .map((val, index) => (
                                <li key={index}>{val.nama_item_aset ?? '-'}</li>
                              ))}
                          </ul>
                          {e?.list_item_aset?.length > 3 &&
                            index !== collapsedIndex && (
                              <Button
                                variant="link"
                                className="pt-0"
                                onClick={() => setCollapsedIndex(index)}
                              >
                                Selengkapnya...
                              </Button>
                            )}
                        </>
                      )}
                    </Td>
                    <Td>{e?.catatan ?? '-'}</Td>
                    <TdFixed>
                      <Button
                        variant={
                          e?.status_checklist === 'approve'
                            ? 'outline-success'
                            : 'outline-danger'
                        }
                        text={
                          e?.status_checklist === 'approve'
                            ? 'Approved'
                            : 'Reject'
                        }
                        disabled
                      />
                    </TdFixed>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getAnalisaChecklist?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getAnalisaChecklist?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
