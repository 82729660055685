import axios from 'axios'
import {
  Alert,
  CreateButton,
  CRUDLayout
} from 'components'
import {
  DataStatus,
  InputSearch
} from 'components2'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import PermintaanWorkOrderApi from './__PermintaanWorkOrderApi__'
import {
  ListTableData
} from './__PermintaanWorkOrderComps__'

export const PermintaanWorkOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const [isLoading, setIsLoading] = useState(true);

  const [params, setParams] = useState({
    q: '',
    bahan: {
      page: 1,
      per_page: 10,
      totalData: '',
      totalPage: 1,
      id_buaso: '1',
    },
    upah: { page: 1, per_page: 10, id_buaso: '2' },
    alat_mesin: {
      page: 1,
      totalData: '',
      per_page: 10,
      totalPage: 1,
      id_buaso: '3',
    },
    subkon: {
      page: 1,
      totalData: '',
      per_page: 10,
      totalPage: 1,
      id_buaso: '4',
    },
    overhead: {
      page: 1,
      totalData: '',
      per_page: 10,
      totalPage: 1,
      id_buaso: '5',
    },
  });

  const [dataBuaso, setDataBuaso] = useState({
    bahan: [],
    upah: [],
    alat_mesin: [],
    subkon: [],
    overhead: [],
  });

  const getData = () => {
    setIsLoading(true);

    axios
      .all([
        PermintaanWorkOrderApi.getList({ q: params.q, ...params.bahan }),
        PermintaanWorkOrderApi.getList({ q: params.q, ...params.upah }),
        PermintaanWorkOrderApi.getList({ q: params.q, ...params.alat_mesin }),
        PermintaanWorkOrderApi.getList({ q: params.q, ...params.subkon }),
        PermintaanWorkOrderApi.getList({ q: params.q, ...params.overhead }),
      ])

      .then(
        axios.spread((bahan, upah, alat_mesin, subkon, overhead) => {
          setDataBuaso({
            bahan: bahan?.data ?? [],
            upah: upah?.data ?? [],
            alat_mesin: alat_mesin?.data ?? [],
            subkon: subkon?.data ?? [],
            overhead: overhead?.data ?? [],
          });

          setParams({
            ...params,
            bahan: {
              ...params.bahan,
              totalPage: bahan?.total_page,
              totalData: bahan?.data_count,
            },
            upah: {
              ...params.upah,
              totalPage: upah?.total_page,
              totalData: upah?.data_count,
            },
            alat_mesin: {
              ...params.alat_mesin,
              totalPage: alat_mesin?.total_page,
              totalData: alat_mesin?.data_count,
            },
            subkon: {
              ...params.subkon,
              totalPage: subkon?.total_page,
              totalData: subkon?.data_count,
            },
            overhead: {
              ...params.overhead,
              totalPage: overhead?.total_page,
              totalData: overhead?.data_count,
            },
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setParams({
      ...params,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + searchKey,
    });
  };

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Work Order');
    checkAlert();

    getData();
  }, [
    params?.q,
    params?.bahan?.page,
    params?.bahan?.per_page,
    params?.upah?.page,
    params?.upah?.per_page,
    params?.alat_mesin?.page,
    params?.alat_mesin?.per_page,
    params?.subkon?.page,
    params?.subkon?.per_page,
    params?.overhead?.page,
    params?.overhead?.per_page,
  ]);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="md"
            icon
            onClick={() => history.push('permintaan-work-order/work-order')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat . . ." />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Permintaan Work Order
            </small>
          </div>
          <Card className="p-0 m-0">
            <Tab.Container defaultActiveKey="bahan">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="bahan">Bahan</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="upah">Upah</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="alat_mesin">Alat Mesin</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="subkon">Subkon</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="overhead">Overhead</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content>
                <Tab.Pane eventKey="bahan" className="p-2">
                  <ListTableData
                    setParams={setParams}
                    params={params}
                    data={dataBuaso?.bahan}
                    type="bahan"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="upah" className="p-2">
                  <ListTableData
                    setParams={setParams}
                    params={params}
                    data={dataBuaso?.upah}
                    type="upah"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="alat_mesin" className="p-2">
                  <ListTableData
                    setParams={setParams}
                    params={params}
                    data={dataBuaso?.alat_mesin}
                    type="alat_mesin"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="subkon" className="p-2">
                  <ListTableData
                    setParams={setParams}
                    params={params}
                    data={dataBuaso?.subkon}
                    type="subkon"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="overhead" className="p-2">
                  <ListTableData
                    setParams={setParams}
                    params={params}
                    data={dataBuaso?.overhead}
                    type="overhead"
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        </>
      )}
    </CRUDLayout>
  );
};
