import {
  Alert,
  CreateButton,
  CRUDLayout,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from 'components'
import {
  ButtonCreate,
  ButtonDetail,
  ButtonFilter,
  ButtonUpdate,
  DataStatus,
  InputSearch,
} from 'components2'
import { Formik } from 'formik'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { useQuery } from 'react-query'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import { tableNumber } from 'utilities2'
import ItemChecklistApi from './__AnalisaChecklistApi__'
import {
  ModalFilter
} from './__AnalisaChecklistComps__'

export const AnalisaChecklistList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    per_page: 10,
    id_grup_aset: undefined,
    id_kategori_aset: undefined,
    id_jenis_aset: undefined,
    id_item_aset: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getAnalisaChecklist = useQuery(
    ['analisa_checklist', 'list', filter],
    () => ItemChecklistApi.getList(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    id_grup_aset: filter?.id_grup_aset,
    id_kategori_aset: filter?.id_kategori_aset,
    id_jenis_aset: filter?.id_jenis_aset,
    id_item_aset: filter?.id_item_aset,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => {
    setNavbarTitle('Analisa Checklist');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="md"
            icon
            onClick={() => history.push('analisa-checklist/item-aset')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getAnalisaChecklist.isLoading || getAnalisaChecklist.isError ? (
        <DataStatus
          loading={getAnalisaChecklist.isLoading}
          text={
            getAnalisaChecklist.isLoading ? 'Memuat . . .' : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Analisa Checklist Item Aset
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th className="p-1">Kode Aset</Th>
                <Th className="p-1">Item Aset</Th>
                <Th className="p-1">Grup Aset</Th>
                <Th className="p-1">Kategori Aset</Th>
                <Th className="p-1">Jenis Aset</Th>
              </Tr>
            </THead>
            <TBody>
              {getAnalisaChecklist?.data?.data?.length > 0 ? (
                getAnalisaChecklist?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup size='sm'>
                        <ReadButton
                          icon
                          noText
                          onClick={() =>
                            history.push(
                              '/asm/master/analisa-checklist/detail/' +
                                e?.id_analisa_checklist
                            )
                          }
                        />
                        <UpdateButton
                          icon
                          noText
                          variant="success"
                          onClick={() =>
                            history.push(
                              '/asm/master/analisa-checklist/ubah/' +
                                e?.id_analisa_checklist
                            )
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                    <Td>{e?.kode_item_aset ?? '-'}</Td>
                    <Td>{e?.nama_item_aset ?? '-'}</Td>
                    <Td>{e?.nama_grup_aset ?? '-'}</Td>
                    <Td>{e?.nama_kategori_aset ?? '-'}</Td>
                    <Td>{e?.nama_jenis_aset ?? '-'}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getAnalisaChecklist?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getAnalisaChecklist?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
