/* eslint-disable react-hooks/exhaustive-deps */
import { AsetLainApi } from 'api'
import {
  ActionButton,
  Alert,
  AlertModal,
  CRUDLayout,
  Input,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  Tr
} from 'components'
import { InputCurrency } from 'components/Input'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RupiahConvert } from 'utilities'
import * as Yup from 'yup'

const PenyusutanAset = ({ tipe, data, setPage }) => {
  const [dataInduk, setDataInduk] = useState({
    penyusutan_saldo_menurun: [],
  });

  const [initialValues, setInitialValues] = useState({
    id_item_aset: data.id_item_aset,
    harga_perolehan: Boolean(data.harga_perolehan) ? data.harga_perolehan : '',
    umur_ekonomis_tahun: Boolean(data.umur_ekonomis_tahun) ? data.umur_ekonomis_tahun : '0',
    penyusutan_garis_lurus_per_tahun: 0,
    penyusutan_garis_lurus_per_bulan: 0,
    nama_jenis_aset: data.nama_jenis_aset,
    nama_golongan_aset: data.nama_golongan_aset,
  })

  const [showAlert, setShowAlert] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getDataPenyusutan = (value, setFieldValue) => {
    AsetLainApi.getPenyusutan(value).then((res) => {
      setDataInduk({
        penyusutan_saldo_menurun: res.data.penyusutan_saldo_menurun,
      });

      setFieldValue(
        'penyusutan_garis_lurus_per_tahun',
        parseFloat(res.data.penyusutan_garis_lurus_per_tahun)
      );
      setFieldValue(
        'penyusutan_garis_lurus_per_bulan',
        parseFloat(res.data.penyusutan_garis_lurus_per_bulan)
      );
    });
  };

  useEffect(() => {
    setDataInduk({
      ...dataInduk,
      penyusutan_saldo_menurun: data.penyusutan,
    });
  }, []);

  useEffect(() => {
    AsetLainApi.getPenyusutan({ harga_perolehan: data?.harga_perolehan, umur_ekonomis: data?.umur_ekonomis_tahun }).then((res) => {
      setInitialValues(prev => ({
        ...prev,
        penyusutan_garis_lurus_per_tahun: parseFloat(res.data.penyusutan_garis_lurus_per_tahun),
        penyusutan_garis_lurus_per_bulan: parseFloat(res.data.penyusutan_garis_lurus_per_bulan),
      }))
      setDataInduk({
        penyusutan_saldo_menurun: res.data.penyusutan_saldo_menurun,
      })
    })
  }, [initialValues.umur_ekonomis_tahun, initialValues.harga_perolehan])

  const [saveAlert, setSaveAlert] = useState(false);

  const [saveAlertConfig, setsaveAlertConfig] = useState({
    textColor: '',
    title: '',
    message: '',
    status: '',
  });

  // skema validasi form
  const formValidationSchema = Yup.object().shape({
    harga_perolehan: Yup.string().required('Harga Perolehan wajib diisi'),
    penyusutan_garis_lurus_per_tahun: Yup.string().required(
      'Penyusutan Garis Lurus Per Tahun tidak boleh kosong'
    ),
    penyusutan_garis_lurus_per_bulan: Yup.string().required(
      'Penyusutan Garis Lurus Per Bulan tidak boleh kosong'
    ),
  });

  const formSubmitHandler = (values) => {
    let harga_perolehan = Boolean(values.harga_perolehan) ? values.harga_perolehan : 0
    let penyusutan_garis_lurus_per_tahun = Boolean(values.penyusutan_garis_lurus_per_tahun) ? parseFloat(values.penyusutan_garis_lurus_per_tahun) : 0
    let penyusutan_garis_lurus_per_bulan = Boolean(values.penyusutan_garis_lurus_per_bulan) ? parseFloat(values.penyusutan_garis_lurus_per_bulan) : 0

    AsetLainApi.getPenyusutan({
      harga_perolehan: harga_perolehan,
      umur_ekonomis: values.umur_ekonomis_tahun,
    }).then((res) => {
      AsetLainApi.updatePenyusutan({
        id_item_aset: values.id_item_aset,
        harga_perolehan: harga_perolehan,
        penyusutan_saldo_menurun: res.data.penyusutan_saldo_menurun,
        penyusutan_garis_lurus_per_tahun: penyusutan_garis_lurus_per_tahun,
        penyusutan_garis_lurus_per_bulan: penyusutan_garis_lurus_per_bulan,
      })
        .then(() => {
          if (tipe === 'tambah') {
            setPage(3);
          } else {
            setsaveAlertConfig({
              textColor: 'text-success',
              title: 'Simpan data berhasil',
              message: 'Data berhasil di ubah',
              status: true,
            });
          }
        })
        .catch((err) => {
          setsaveAlertConfig({
            textColor: 'text-danger',
            title: 'Tambah data gagal',
            message: err.response.data.message,
            status: false,
          });
        })
        .finally(() => {
          setSaveAlert(true);
        });
    });
  };

  return (
    <>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <>
        <Formik
        enableReinitialize
          initialValues={initialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <ColumnData title="Data Dasar Aset">
                <Row>
                  <Col lg>
                    <Input
                      label="Jenis Aset"
                      type="text"
                      name="nama_jenis_aset"
                      value={values.nama_jenis_aset}
                      readOnly={true}
                      onChange={handleChange}
                    />

                    <InputCurrency
                      label="Harga Perolehan Aset"
                      placeholder="Masukan Harga Perolehan Aset"
                      value={parseFloat(values.harga_perolehan)}
                      disabled={tipe !== 'detail' ? false : true}
                      onChange={(e) => {
                        setFieldValue('harga_perolehan', e);
                      }}
                      onBlur={(e) => {
                        let number = e.target.value.replace('Rp', '');
                        let angka = parseFloat(
                          number.replaceAll('.', '').replace(',', '.')
                        );

                        angka !== '' &&
                          values.umur_ekonomis_tahun !== '0' &&
                          getDataPenyusutan(
                            {
                              harga_perolehan: angka,
                              umur_ekonomis: values.umur_ekonomis_tahun,
                            },
                            setFieldValue
                          );
                      }}
                      error={
                        errors.harga_perolehan &&
                        touched.harga_perolehan &&
                        true
                      }
                      errorText={errors.harga_perolehan}
                    />

                    <InputCurrency
                      label="Penyusutan Garis Lurus Per Tahun"
                      value={parseFloat(
                        values.penyusutan_garis_lurus_per_tahun
                      )}
                      disabled={true}
                      error={
                        errors.penyusutan_garis_lurus_per_tahun &&
                        touched.penyusutan_garis_lurus_per_tahun &&
                        true
                      }
                      errorText={errors.penyusutan_garis_lurus_per_tahun}
                      onChange={(e) =>
                        setFieldValue('penyusutan_garis_lurus_per_tahun', e)
                      }
                    />
                  </Col>
                  <Col lg>
                    <Input
                      label="Golongan Aset"
                      type="text"
                      name="nama_golongan_aset"
                      value={values.nama_golongan_aset}
                      readOnly={true}
                      onChange={handleChange}
                    />

                    <Input
                      label="Umur Ekonomis (Tahun)"
                      type="text"
                      name="umur_ekonomis_tahun"
                      value={values.umur_ekonomis_tahun}
                      readOnly={true}
                      onChange={handleChange}
                    />

                    <InputCurrency
                      label="Penyusutan Garis Lurus Per Bulan"
                      value={parseFloat(
                        values.penyusutan_garis_lurus_per_bulan
                      )}
                      disabled={true}
                      error={
                        errors.penyusutan_garis_lurus_per_bulan &&
                        touched.penyusutan_garis_lurus_per_bulan &&
                        true
                      }
                      errorText={errors.penyusutan_garis_lurus_per_bulan}
                      onChange={(e) =>
                        setFieldValue('penyusutan_garis_lurus_per_bulan', e)
                      }
                    />
                  </Col>
                </Row>

                <small>Penyusutan Saldo Menurun</small>
                <CRUDLayout.Table>
                  <THead>
                    <Tr>
                      <Th>Tahun Ke</Th>
                      <Th>Penyusutan Per Tahun</Th>
                      <Th>Penyusutan Per Bulan</Th>
                    </Tr>
                  </THead>

                  <TBody>
                    {dataInduk?.penyusutan_saldo_menurun?.length > 0 ? (
                      dataInduk?.penyusutan_saldo_menurun?.map((val, index) => {
                        return (
                          <Tr key={index}>
                            <TdFixed>{val.tahun_ke}</TdFixed>
                            <Td>
                              <div className="text-right">
                                {
                                  RupiahConvert(
                                    parseFloat(
                                      val.penyusutan_saldo_menurun_per_tahun
                                    ).toString()
                                  ).getWithComa
                                }
                              </div>
                            </Td>
                            <Td>
                              <div className="text-right">
                                {
                                  RupiahConvert(
                                    parseFloat(
                                      val.penyusutan_saldo_menurun_per_bulan
                                    ).toString()
                                  ).getWithComa
                                }
                              </div>
                            </Td>
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <TdFixed colSpan="3">Tidak ada data</TdFixed>
                      </Tr>
                    )}
                  </TBody>
                </CRUDLayout.Table>
              </ColumnData>

              {tipe !== 'detail' && (
                <div className="text-right">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text={tipe.toUpperCase()}
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              )}
            </form>
          )}
        </Formik>
        <AlertModal
          show={saveAlert}
          onHide={() => setSaveAlert(false)}
          textColor={saveAlertConfig.textColor}
          title={saveAlertConfig.title}
          message={saveAlertConfig.message}
          link="/asm/master/item-aset/aset-lain"
          status={saveAlertConfig.status}
        />
      </>
    </>
  );
};

  export const ColumnData = ({ title, className, children }) => (
    <div className={className ? className : 'mb-4'}>
      <h6
        className="ml-1 text-dark"
        style={{
          fontSize: '17px',
          marginBottom: '12.5px',
        }}
      >
        <b>{title}</b>
      </h6>
      <div className="bg-white p-2 px-3 shadow-sm rounded">{children}</div>
    </div>
  );

export default PenyusutanAset;
